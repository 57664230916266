// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoWidget_BMVhp {\n  display: flex;\n  flex-direction: column;\n  min-width: 110px;\n  max-width: 190px;\n  width: 33%;\n  margin: 0 24px;\n  padding: 8px 0;\n}\n.infoWidget_BMVhp:first-child {\n  margin: 0 24px 0 0;\n}\n.infoWidget_BMVhp .infoWidgetLabel_jVEnt {\n  font-family: SF Pro Display;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  text-align: right;\n  color: #868991;\n}\n.infoWidget_BMVhp .infoWidgetValue_ISm31 {\n  width: 100%;\n  text-align: right;\n  font-family: SF Pro Display;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.infoWidget_BMVhp .infoWidgetValue_ISm31 .special_RCEkA {\n  color: #868991;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/Balance/components/InfoWidget/style.m.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;EACA,cAAA;EACA,cAAA;AADF;AAGE;EACE,kBAAA;AADJ;AATA;EAcI,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAlBA;EAwBI,WAAA;EACA,iBAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHJ;AA5BA;EAkCM,cAAA;AAHN","sourcesContent":["@import 'style/palette';\n\n.infoWidget {\n  display: flex;\n  flex-direction: column;\n  min-width: 110px;\n  max-width: 190px;\n  width: 33%;\n  margin: 0 24px;\n  padding: 8px 0;\n\n  &:first-child {\n    margin: 0 24px 0 0;\n  }\n\n  .infoWidgetLabel {\n    font-family: SF Pro Display;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    text-align: right;\n    color: @gray-7;\n  }\n\n  .infoWidgetValue {\n    width: 100%;\n    text-align: right;\n    font-family: SF Pro Display;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: @gray-10;\n\n    .special {\n      color: @gray-7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoWidget": "infoWidget_BMVhp",
	"infoWidgetLabel": "infoWidgetLabel_jVEnt",
	"infoWidgetValue": "infoWidgetValue_ISm31",
	"special": "special_RCEkA"
};
export default ___CSS_LOADER_EXPORT___;
