// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".collapse_btn_WdWoO span {\n  font-weight: 400;\n}\n.collapse_header_aXwEK {\n  padding: 0 !important;\n}\n.collapse_header_aXwEK .ant-btn {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/Balance/components/Debit/RequisitesCollapse/RequisitesCollapse.m.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAIA;EAME,qBAAA;AAPF;AACA;EAGQ,UAAA;AADR","sourcesContent":[".collapse_btn {\n  span {\n    font-weight: 400;\n  }\n}\n\n.collapse_header {\n    :global {\n      .ant-btn {\n        padding: 0;\n      }\n    }\n  padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse_btn": "collapse_btn_WdWoO",
	"collapse_header": "collapse_header_aXwEK"
};
export default ___CSS_LOADER_EXPORT___;
