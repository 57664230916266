// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tax_list_title_ATqLB {\n  font-weight: 600;\n  font-size: 14;\n}\n.tax_list__HDEH {\n  display: flex;\n  flex-direction: column;\n  padding: 0 12px;\n}\n.tax_row_AinLq {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 0;\n  border-bottom: 1px solid #ebecf0;\n}\n.tax_row_label__BLA8 {\n  color: #868991;\n  width: 60%;\n}\n.tax_row_value_F94Bn {\n  display: flex;\n  flex-direction: column;\n  width: 40%;\n  text-align: right;\n  color: #868991;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/Balance/components/Debit/TaxList/TaxList.m.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,aAAA;AADF;AAIA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AAFF;AAKA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AAHF;AAKE;EACE,cAAA;EACA,UAAA;AAHJ;AAME;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,iBAAA;EACA,cAAA;AAJJ","sourcesContent":["@import 'style/palette';\n\n.tax_list_title {\n  font-weight: 600;\n  font-size: 14;\n}\n\n.tax_list {\n  display: flex;\n  flex-direction: column;\n  padding: 0 12px\n}\n\n.tax_row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 0;\n  border-bottom: 1px solid @gray-4;\n\n  &_label {\n    color: @gray-7;\n    width: 60%;\n  }\n\n  &_value {\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n    text-align: right;\n    color: @gray-7;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tax_list_title": "tax_list_title_ATqLB",
	"tax_list": "tax_list__HDEH",
	"tax_row": "tax_row_AinLq",
	"tax_row_label": "tax_row_label__BLA8",
	"tax_row_value": "tax_row_value_F94Bn"
};
export default ___CSS_LOADER_EXPORT___;
