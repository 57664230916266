import { BankAccountDetailsModel } from "@dltru/dfa-models"
import { Button, Collapse, Divider, DownMini, Requisites } from "@dltru/dfa-ui"
import { FC, useState } from "react"
import { bankReq, clientBankReq } from "./requisites"
import styles from './RequisitesCollapse.m.less'

const COLLAPSE_KEY = 'default'

type ComponentProps = {
  profileBankAccountDetails: BankAccountDetailsModel
}

export const RequisitesCollapse: FC<ComponentProps> = ({ profileBankAccountDetails }) => {
  const [activeKey, setActiveKey] = useState<string | string[]>()

  return (
    <Collapse
      ghost
      activeKey={activeKey}
      onChange={setActiveKey}
    >
      <Collapse.Panel
        headerClass={styles.collapse_header}
        header={<Button type="link" className={styles.collapse_btn}>Реквизиты зачисления денежных средств<DownMini /></Button>}
        key={COLLAPSE_KEY}
        showArrow={false}
      >
        <Requisites requisites={clientBankReq(profileBankAccountDetails)} />
        <Divider margin={[16, 0]} />
        <Requisites requisites={bankReq(profileBankAccountDetails)} />
      </Collapse.Panel>
    </Collapse>
  )
}