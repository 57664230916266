import { IDfaFront } from '@dltru/dfa-models'
import { Box, Button, CaseAdd, DEFAULT_PER_PAGE, Divider, Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { dropDfaDetails } from '@store/dfa/details'
import { dropDfaList, loadDfas } from '@store/dfa/list'
import { dfaListSelectors } from '@store/dfa/list/selectors'

import { gridConfig } from '../../../../MyEmissions/gridConfig'
import { profileSelector } from '@store/profiles/details/selectors'

export const DfasTab: FC = () => {
    const { items, paging } = useSelector(dfaListSelectors.selectList)
    const uuid = useSelector(authSelector.selectUserUid)
    const isLoading = useSelector(dfaListSelectors.selectIsLoading)
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const isEmissionDisabled = useSelector(profileSelector.isPartlyBlocked)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(loadDfas({ limit, emitter_id: uuid }))
    }

    const onNext = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(loadDfas({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(loadDfas({ emitter_id: uuid, limit: DEFAULT_PER_PAGE }))
        reduxDispatch(dropDfaDetails())
        return () => {
            reduxDispatch(dropDfaList())
        }
    }, [])

    const onRow = (record: IDfaFront) => ({
        onClick: () => {
            navigate(`/offers/${record.id}`)
        },
    })

    return (
        <Box padding={[0, 24, 24, 24]} className="tablebox">
            <Table
                isLoading={isLoading}
                columns={gridConfig}
                dataSource={items ?? []}
                cursors={paging?.cursors}
                onPerPage={onPerPageChange}
                onNext={onNext}
                onPrev={onPrev}
                onRow={onRow}
                clickableRow
                rowKey="id"
            />
            <Divider margin={[16, 0]} />
            <Box direction="row" align="left">
                <Button
                    onClick={() => navigate('/issue-decision')}
                    type="primary"
                    icon={<CaseAdd />}
                    borderRadius={12}
                    width="160px"
                    disabled={isEmissionDisabled}
                    tooltipOnDisable="Действие недоступно. Ваш профиль частично заблокирован."
                >
                    Выпустить ЦФА
                </Button>
            </Box>
        </Box>
    )
}
