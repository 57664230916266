import { Button, RefillModal } from '@dltru/dfa-ui'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { moneySelector } from '@store/money/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

const Refill: FC = () => {
    const [isModal, setIsModal] = useState(false)
    const account = useSelector(moneySelector.selectAccount)
    const profileWallet = useSelector(profileSelector.selectWalletDfaData)

    return (
        <>
            <Button
                onClick={() => setIsModal(true)}
                type="primary"
                style={{ marginRight: 16, borderRadius: 12 }}
                disabled={!profileWallet}
                tooltipOnDisable="Операция временно недоступна. Повторите попытку позже"
            >
                Пополнить
            </Button>

            <RefillModal isModal={isModal} setIsModal={setIsModal} account={account} />
        </>
    )
}

export default Refill
