import { Price, LinkToTariff, VIEW_DATE_FORMAT, getRublesFromPennies } from '@dltru/dfa-ui'
import styles from './TaxList.m.less'
import { FC } from 'react'
import dayjs from 'dayjs'

type ComponentProps = {
  showTaxInfo?: boolean,
  fee: number, 
  taxAmount: number,
  calculatedTaxAmount: number,
  amount: number,
  total: number
}

export const TaxList: FC<ComponentProps> = ({ 
  fee, 
  taxAmount, 
  calculatedTaxAmount, 
  amount, 
  total, 
  showTaxInfo
 }) => {
  return <>
    <span className={styles.tax_list_title}>Из суммы списания будет удержано: </span>
    <div className={styles.tax_list}>
      {
        showTaxInfo && <div className={styles.tax_row}>
          <span className={styles.tax_row_label}>
            НДФЛ за текущий налоговый период <br/>
            {dayjs().format(VIEW_DATE_FORMAT)}
          </span>
          <div className={styles.tax_row_value}>
            <Price price={Math.min(calculatedTaxAmount, amount) / 100}/>
            <span>из {getRublesFromPennies(taxAmount || 0)} ₽</span>
          </div>
        </div>
      }
      <div className={styles.tax_row}>
        <span className={styles.tax_row_label}>
          Комиссия за исполнение платежного поручения согласно <LinkToTariff />
        </span>
        <div className={styles.tax_row_value}>
          <Price price={fee / 100} />
        </div>
      </div>
      <div className={styles.tax_row}>
        <span className={styles.tax_row_label}>
          Итого к выводу
        </span>
        <div className={styles.tax_row_value}>
          <Price price={total / 100} />
        </div>
      </div>
    </div>
  </>
}