import { BankAccountDetailsModel } from '@dltru/dfa-models'

export const clientBankReq = (value?: BankAccountDetailsModel | null) => {
    if (!value) {
        return []
    }

    return [
        { label: 'БИК', value: value.bic },
        { label: 'Расчётный счёт', value: value.account_number },
    ]
}

export const bankReq = (value?: BankAccountDetailsModel | null) => {
    if (!value) {
        return []
    }

    return [
        { label: 'Банк получателя', value: value.bank },
        { label: 'Корр.счет', value: value.corr_account_number },
        { label: 'Получатель', value: value.recipient },
        { label: 'ИНН получателя', value: value.recipient_inn },
        { label: 'КПП получателя', value: value.recipient_kpp },
    ]
}
